<script setup lang="ts">
import type { OrderLineItem } from "@shopware-pwa/types";
import type { LineItem, Promotion } from "@shopware-pwa/types";
import debounce from "lodash/debounce";
import { formatPrice } from "~/helpers/formatters";
import { getThumbnailUrl } from "~/helpers/mediaHelpers";
import { getExclusiveDeliveryMethodMessage } from "~/helpers/shippingMethodHelpers";

defineProps<{
	readOnly?: boolean;
	items?: OrderLineItem[] | null;
}>();

const { cartItems, removeItem, changeProductQuantity } = useCart();
const { onRemoveFromCart } = useGtagEvents();

const isUpdatingCart = ref<boolean>(false);

const isPromotion = (item: LineItem | OrderLineItem): item is LineItem & { payload: Promotion } => {
	return "promotionId" in item.payload;
};

const isOrderLineItem = (item: LineItem | OrderLineItem): item is OrderLineItem => {
	return "apiAlias" in item && item.apiAlias === "order_line_item";
};

const updateCartItem = debounce(async (item: LineItem | OrderLineItem, quantity: number) => {
	if (isOrderLineItem(item)) return;

	isUpdatingCart.value = true;
	await changeProductQuantity({
		id: item.id,
		quantity
	});
	isUpdatingCart.value = false;
}, 500);

const removeCartItem = async (item: LineItem | OrderLineItem) => {
	if (isOrderLineItem(item)) return;

	isUpdatingCart.value = true;
	await removeItem(item);
	onRemoveFromCart(item, item.quantity);
	isUpdatingCart.value = false;
};

const getItemTotalPrice = (item: LineItem | OrderLineItem) => {
	if (item.apiAlias === "order_line_item") {
		return formatPrice(item.totalPrice);
	}

	return formatPrice(item.price?.totalPrice);
};
</script>

<template>
	<template v-for="item in items || cartItems" :key="item.id">
		<div
			class="bg-sand py-4 px-4 rounded text-sm flex justify-between gap-2 items-center"
			:class="{
				'pointer-events-none opacity-50': isUpdatingCart
			}"
			v-if="isPromotion(item)"
		>
			<span class="font-medium grow">
				{{ item.label }}
			</span>
			<span class="">
				{{ getItemTotalPrice(item) }}
			</span>

			<button class="p-0 m-0 max-h-min text-gray hover:text-black-light" @click="removeCartItem(item)">
				<SvgIcon name="trash" />
			</button>
		</div>

		<KippieCartItem
			v-if="!isPromotion(item)"
			:title="item.label || ''"
			:price="getItemTotalPrice(item)"
			:quantity="item.quantity"
			:stackable="readOnly ? false : item.stackable"
			:removable="readOnly ? false : !!item.removable"
			:class="{
				'pointer-events-none opacity-50': isUpdatingCart
			}"
			@update="updateCartItem(item, $event)"
			@remove="removeCartItem(item)"
		>
			<template #image>
				<img :src="item.cover ? getThumbnailUrl(item.cover) : ''" width="50" />
			</template>

			<span class="text-xs text-gray flex flex-col">
				<span>
					{{ (item.payload as KippieProduct).productCustomFields?.kippie_product_fields_first_label }}
				</span>
				<span>
					{{ (item.payload as KippieProduct).productCustomFields?.kippie_product_fields_second_label }}
				</span>
			</span>

			<div
				class="rounded-full text-xs bg-gray-200 text-gray px-2 py-0.5 font-medium self-start mt-4"
				v-if=" getExclusiveDeliveryMethodMessage(item.payload as KippieProduct)"
			>
				{{ getExclusiveDeliveryMethodMessage(item.payload as KippieProduct) }}
			</div>
		</KippieCartItem>

		<Divider class="last-of-type:hidden" />
	</template>
</template>
